import React, { useEffect, useState } from "react";
import { useGame } from "../../context/games.context";
import { useNavigate, useParams } from "react-router-dom";
import { Container, DivPai, ContainerInfo, DivContainerInfo,DivInfosEntrada } from "./styles";
import {useControles} from '../../context/controles.context';
import { BackgroundWrapper, PrimaryButton, PrimaryButtonNoRadius } from "../../globalComponents";
import { HackingLoading } from "../Loadings/HackingLoading";
import SepararDadosRegex from "../../utils/tratador";
import ModalTabela from "../ModalTabela";
import { useWhitelabel } from "../../context/whitelabel.context";

const CardSalaDeJogos = () => {
    const listaSegundos = [3,4,5,6,7,8,9,10,11,12]
    const cincoMinutos = 300 //mudar pra 300

    const {textoBotaoAnalisando} = useWhitelabel()

    const {setActiveGame, GetJogada, activeGame, GetJogadaTelegram} = useGame()
    
    const [entrada, setEntrada] = useState()
    const [entradaTelegram, setEntradaTelegram] = useState()

    const navigate = useNavigate()
    const [activeJogada, setActiveJogada] = useState()
    const {roletaId, integracao, jogo} = useParams()
    const { isMobile, setSideBarStatus } = useControles()
    const [cellScreen, setCellScreen] = useState()

    const [hackStatus, setHackStatus] = useState()
    const [analisandoStatus, setAnalisandoStatus] = useState(false)

    const [isGreen, setIsGreen] = useState(false)
    const [listaUltimos, setListaUltimos] = useState([])

    const [grafico, setGrafico] = useState()

    const handleResize = () => {
        setCellScreen(window.innerWidth <= 770)
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
    
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(()=>{
        if(!activeGame){
            navigate('../')
        }
    }, [])

    useEffect(()=>{
        setSideBarStatus(false)
    }, [])

    const fetchData = async () => {
        const response = await GetJogada(roletaId)
        setEntrada(response.data[0])
        if(roletaId == 3){
            setGrafico(response.data[0].validade.replace(/🟦/g, '#').replace(/⭐️/g, '$').split(''))
        }
    }

    const fetchDataTelegram = async () => {
        
        const response = await GetJogadaTelegram(roletaId)
        const responseTratado = SepararDadosRegex(response.data[0])
        
        setEntradaTelegram(responseTratado)
        // console.log(responseTratado)
        return responseTratado
    }

    

    const zerarHack = async () => {
        setHackStatus(false)
        setListaUltimos(null)
        setAnalisandoStatus(false)
    }

    const handleHackear = async () => {
        setAnalisandoStatus(true)
        fetchData()
        
        const numeroEscolhidoIndex = Math.floor(Math.random() * listaSegundos.length);
        const numeroEscolhido = listaSegundos[numeroEscolhidoIndex];

        if(integracao == 0) {

            const interval = setTimeout(()=>{
                setHackStatus(true)
            }, 1000 * numeroEscolhido)

        } else if (integracao == 1) {
            await fetchDataTelegram()

            const interval = setInterval(async ()=>{
                const response = await fetchDataTelegram()
                if(response.estado) {
                    if(response.estado != listaUltimos[listaUltimos.length - 1] && response.ativo == "1"){
                        const listaAuxiliar = listaUltimos
                        listaAuxiliar.push(response.estado)
                        
                        setListaUltimos(listaAuxiliar)
                        if(listaAuxiliar[listaAuxiliar.length - 1].includes("GREEN") || listaAuxiliar[listaAuxiliar.length - 1].includes("RED")){
                            const timeout = setTimeout(() => {
                                setIsGreen(true)
                            }, 5000)

                        } else {
                            setIsGreen(false)
                        }
                    }
                }
                
                if(response.ativo == "0"){
                    setHackStatus(false)    
                } else {
                    if(!isGreen){
                        setHackStatus(true)
                    }
                    
                }
            }, 4000)

            return () => clearInterval(interval)
        }
        // return () => clearTimeout(interval)
    }

    useEffect(() => {
        if(integracao == 1){
            console.log('Verificando ultimo estado => ', listaUltimos[listaUltimos.length - 1])
    
            if (listaUltimos.length === 0 || listaUltimos[listaUltimos.length - 1].includes('GREEN') || listaUltimos[listaUltimos.length - 1].includes('RED')) {
                console.log("Esperando próxima análise.")
        
                const time = setTimeout(() => {
                    console.log("Analisando...")
                    setHackStatus(false)
                    setAnalisandoStatus(true)
                    setIsGreen(true)
                }, 5000);
        
                
            } else {
                setIsGreen(false);
            }
        }
        
    }, [listaUltimos, isGreen]);
    
    

    useEffect(()=>{
       if(hackStatus){
            const interval = setInterval(()=>{
                setAnalisandoStatus(false)
                setHackStatus(false)
            }, 1000 * cincoMinutos)

            return () => clearInterval(interval)
       }
    }, [hackStatus])


    const {verifySaldo, credits} = useWhitelabel()
    const {openDeposit, setopenDeposit} = useControles()


    const handleDeposit = () => {
            setopenDeposit(true)
        }


    if(verifySaldo == 1 && credits == '0') {
            
            return (
                <Container style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '80vh'}}>
                    <div style={{border: '2px solid var(--Primary-color)', width: '400px', maxWidth: '300px', height: '350px', maxHeight: '350px', borderRadius: '16px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '64px'}}>
                        <h1 style={{color: 'white', textAlign: 'center', width: '100%'}}>
                            Usuário sem saldo.
                        </h1>
                        <PrimaryButton style={{height: 40, padding: 15}}
                            onClick={()=>{
                                handleDeposit()
                            }}>
                                Deposite e Jogue
                        </PrimaryButton>
                    </div>
                </Container>
            )
        }

    

    return (
        <>
        {!hackStatus || (isGreen && integracao == 1) ? 
        
        <>
            <Container style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '80vh'}}>
                {!analisandoStatus ? 
                    <PrimaryButton onClick={handleHackear} style={{width: '250px', height: '250px', borderRadius: '300px', fontSize: '28px', boxShadow: '0px 0px 5px 2px var(--Primary-color)', fontWeight: '600'}}>
                        {textoBotaoAnalisando}
                    </PrimaryButton>
                    :
                    <>
                        <HackingLoading/>
                    </>
                }
            
            </Container>
        </> 
        
        
        : 
        
        <>
        <Container>
            <DivPai>
                <div 
                    style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', maxWidth: '1024px', width: '100%', height: '100%', padding: '10px 10px 20px 10px', borderRadius: '16px', gap: 20}}
                >
                <ModalTabela/>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: !cellScreen && 'space-between', alignItems: 'center', width: '100%', height: 'auto', gap: isMobile && 10}}>
                    {!cellScreen && activeGame && 
                        <img src={activeGame.urlIcone} style={{maxWidth: '150px', borderRadius: '16px', height: '150px'}}/>
                    }
                    
                    <DivInfosEntrada>
                        
                        {/* {(entradaTelegram && entradaTelegram.estrategia) || (entrada && entrada.entrada) &&
                            <DivContainerInfo>
                                <label>{entrada ? entrada.inputValue : "Estratégia"}</label>
                                <ContainerInfo style={{minWidth: cellScreen && roletaId == 3 ? '100px' : '240px'}}>
                                    {entradaTelegram && entradaTelegram.estrategia ? 
                                        entradaTelegram.estrategia : 
                                        entrada.entrada}
                                </ContainerInfo>
                            </DivContainerInfo>
                        } */}
                        
                            <DivContainerInfo>
                                <label>{entrada ? entrada.inputValue : "Estratégia"}</label>
                                <ContainerInfo>
                                    {entradaTelegram && entradaTelegram.estrategia ? entradaTelegram.estrategia : entrada.entrada}
                                </ContainerInfo>
                            </DivContainerInfo>
                        
                        
                        
                        
                        {entrada && !entrada.tentativas.includes("Null")  && 
                            <DivContainerInfo>
                                <label>{entrada ? entrada.inputValue2 : "Tentativas"}</label>
                                <ContainerInfo style={{minWidth: cellScreen && roletaId == 3 ? '100px' : '240px'}}>
                                    {jogo == "OFFLINE" ? entrada.tentativas : jogo == "ROLETA" ? "Cobrir 0": jogo == "CARDS" ? "Cobrir empate" : jogo == "CRASH" ? "3x" : "Cobrir Any Triple"}
                                </ContainerInfo>
                            </DivContainerInfo>
                        }
                        
                        {entrada && !entrada.validade.includes("Null") && roletaId != 3 && 
                            <DivContainerInfo>
                                <label>{entrada ? entrada.inputValue3 : "Dica"}</label>
                                <ContainerInfo style={{minWidth: cellScreen && roletaId == 3 ? '100px' : '240px'}}>
                                    {entrada.validade}
                                    
                                </ContainerInfo>
                            </DivContainerInfo>
                        }
                        {entradaTelegram && integracao == '1' && 
                            <DivContainerInfo>
                                <label>Ação</label>
                                <ContainerInfo style={{minWidth: cellScreen && roletaId == 3 ? '100px' : '240px'}}>
                                    {entradaTelegram.estado}
                                </ContainerInfo>
                            </DivContainerInfo>
                        }
                        
                        
                    </DivInfosEntrada>

                    {roletaId == 3 && 
                    <div style={{display: 'flex', width: isMobile ? '150px' : '200px', height: isMobile ? '120px' : '150px', alignItems: 'center', justifyContent: 'center', background: 'var(--Toggle-color)', borderRadius: '8px', padding: '10px', marginTop: isMobile && 10}}>
                        <div style={{width: '100%',height: '100%', zIndex: 999, display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr', alignItems: 'center', justifyContent: 'center', gap: 2, paddingLeft: 5}}>
                            {entrada && grafico.map((i, indice) => {
                                
                                if(indice < 25){
                                    return (
                                        <span style={{fontSize: isMobile ? '12px' : '16px'}}>{i == '#' ? '🟦' : '⭐️'}</span>
                                    )
                                }
                            })}
                            
                        </div>
                    </div>
                    }
                </div>

                <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <iframe src={
                        activeGame && 
                        activeGame.linkJogo.replace('{token}', localStorage.getItem('tokenBetfive'))
                        .replace('{mobToken}', encodeURI(localStorage.getItem('MobToken')))} 
                    
                    style={{width: '100%', height: '100%', minHeight: '500px', border: '1px solid var(--Primary-color)', borderRadius: '8px'}}>
                    </iframe>
                    
                </div>
                    <PrimaryButtonNoRadius style={{width: '100%'}} onClick={() => {
                        zerarHack()
                        handleHackear()
                    }}>Hackear novamente</PrimaryButtonNoRadius>
                </div>
            </DivPai>
            


        </Container>
        </>}
        
        <BackgroundWrapper>
            <div className="bgImage">
            </div>
        </BackgroundWrapper>
        </>
    )
}

export default CardSalaDeJogos