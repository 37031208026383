import React, { useEffect } from "react";
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import ContainerPages from "../pages/ContainerPages";
import Perfil from "../pages/Perfil";
import Menu from "../pages/Menu";
import Games from "../pages/Games";
import Academy from "../pages/Academy";
import Wizard from "../pages/Wizard";
import { useAuth } from "../context/auth.context";
import Live from "../pages/Live";
import SalaDeJogos from "../pages/SalaDeJogos";

export default function SignedRoutes(){
        const { statusWizard, authStatus, VerifAuth } = useAuth()
        useEffect(()=>{
            //console.log(statusWizard)
        }, [statusWizard])

        

        return (
            <>
                <BrowserRouter>
                    
                    <Routes>
                        
                        
                        
                        <Route path="/" element={<ContainerPages/>}>
                            <Route path="/Wizard" element={<Wizard/>}/>
                            <Route path="/" element={<Games/>}/>
                            <Route path="/Menu" element={<Menu/>}/>
                            <Route path="/Perfil" element={<Perfil/>}/>
                            <Route path="/Academy" element={<Academy/>}/>
                            <Route path="/Games" element={<Games/>}/>
                            <Route path="/Live" element={<Live/>}/>
                            <Route path="/SalaDeJogos" element={<SalaDeJogos/>}/>
                            <Route path="/SalaDeJogos/:roletaId/:integracao/:jogo" element={<SalaDeJogos/>}/>
                            <Route path="/:qualquerCoisa" element={<Menu/>}/>
                        </Route>
                        
                    </Routes>
                
                </BrowserRouter>
            </>
        )
    
}