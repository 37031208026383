import React, {useState, useEffect} from "react";
import { useControles } from "../../context/controles.context";
import { PrimaryButton, PrimaryButtonNoRadius, SecondaryButton, SecondaryButtonNoRadius } from "../../globalComponents";
import { HeaderDiv, HeaderLogoMobile, HeaderContent, ProfileIcon, HeaderButtons } from "./styles";

import { Icon } from '@iconify/react';

import { useNavigate } from 'react-router-dom';
import SideBar from "../SideBar";
import { useWhitelabel } from "../../context/whitelabel.context";
import { SaldoDisponivel } from "../../services/betfive";
import ModalTabela from "../ModalTabela";
import axios from 'axios'
import { io } from "socket.io-client";

const Header = () => {
    const {isMobile, setOpen, setTituloModal, setUrlModal, setActivePage, sideBarStatus, setSideBarStatus, setopenDeposit, setopenSaque} = useControles()
    const {urlSacar, urlDepositar, logoHorizontal, logoVertical, credits, setcredits} = useWhitelabel()
    
    

    const handleOpen = () => {
        setOpen(true)
    };
    const navigate = useNavigate()

    const handleVoltar = () => {
        setSideBarStatus(true)
        navigate('/games')
    }

    

    const handleDeposit = () => {
        setopenDeposit(true)
    }

    const handleSaque = () => {
        setopenSaque(true)
    }

    const fetchSaldoDisponivel = async () => {
        const saldo = localStorage.getItem('saldo')
        setcredits(saldo) 
    }

    useEffect(() => {
        fetchSaldoDisponivel()
    }, [])

    return (
        <>
            {isMobile ? 
                <>
                <HeaderDiv>
                    <HeaderContent>
                        <HeaderLogoMobile>
                           {sideBarStatus ? <img src={logoVertical} style={{maxWidth: "40%"}}></img> : <>
                           <div style={{display: 'flex', alignItems: 'center', gap: 5, cursor: 'pointer', color: 'var(--Primary-text)', fontSize: '20px'}} onClick={handleVoltar}>
                                <Icon icon="ph:arrow-left-bold" />
                                    <span>Voltar</span>
                                </div>
                            </>} 
                        </HeaderLogoMobile>
                        <HeaderButtons>
                            
                                <div style={{color: 'white', background: '#504f4f5d', padding: '16px 16px', height: '40px', display: 'flex', fontSize: '16px', color: 'var(--Primary-color)', alignItems: 'center', borderRadius: '8px' }}>
                                    {parseFloat(credits).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                </div>
                                <SecondaryButton style={{height: 40, padding: 15}}
                                onClick={()=>{
                                    handleSaque()
                                }}>
                                    Sacar
                                </SecondaryButton>
                                <PrimaryButton style={{height: 40, padding: 15}}
                                onClick={()=>{
                                    handleDeposit()
                                }}>
                                    Depositar
                                </PrimaryButton>
                            <ProfileIcon style={{width:40, height: 40, margin: 0, cursor: 'pointer'}} onClick={()=>{navigate("/perfil")
                        setActivePage(4)
                        }}>
                                <Icon icon="iconamoon:profile-fill" />
                            </ProfileIcon>
                        </HeaderButtons>
                    </HeaderContent>
                </HeaderDiv>
                </> : 
                <>
                <HeaderDiv>
                    <HeaderContent>
                        <div style={{marginLeft: "3rem"}}>
                            {sideBarStatus ? <img src={logoHorizontal} style={{width: '150px', maxWidth: '150px', maxHeight: '60px', objectFit: 'contain'}} onClick={() => navigate('/games')}></img> : 
                            <>
                                <div style={{display: 'flex', alignItems: 'center', gap: 5, cursor: 'pointer', color: 'var(--Primary-text)', fontSize: '20px'}}onClick={() => navigate('/games')}
>
                                <Icon icon="ph:arrow-left-bold" />
                                    <span>Voltar</span>
                                </div>
                            </>} 
                        </div>
                        <HeaderButtons>
                            
                                <div style={{color: 'white', background: '#504f4f5d', padding: '16px 16px', height: '40px', display: 'flex', fontSize: '16px', color: 'var(--Primary-color)', alignItems: 'center', borderRadius: '8px' }}>
                                    {parseFloat(credits).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                </div>
                                <SecondaryButtonNoRadius style={{padding: 16}}
                                onClick={()=>{
                                    handleSaque()
                                }}>Sacar</SecondaryButtonNoRadius>
                                <PrimaryButtonNoRadius style={{padding: 16}}
                                onClick={()=>{
                                    handleDeposit()
                                }}>Depositar</PrimaryButtonNoRadius>
                            <ProfileIcon style={{margin: "0 20px", cursor: 'pointer'}} onClick={()=>{navigate("/perfil")
                        setActivePage(4)
                        }} >
                                <Icon icon="iconamoon:profile-fill" />
                            </ProfileIcon>
                        </HeaderButtons>
                    </HeaderContent>
                </HeaderDiv>
                
                </>
            }
            {!isMobile && sideBarStatus && <SideBar/>}
        </>
    )
}

export default Header